body {
  font-size: 2rem;
  max-width: 1000px;
  margin: auto;
  padding: 0 0.3em;
  line-height: 0.96;
  font-family: 'Nimbus Roman', Times, 'Times New Roman', serif;
}

@media only screen and (max-width: 500px) {
  body {
    font-size: 1.5rem;
  }
}

a,
strong {
  font-size: 0.91em;
  font-weight: bold;
  font-family: 'Nimbus Sans', 'Helvetica Neue', Helvetica, Arial, serif;
}

a[href^="http://"]:after,
a[href^="https://"]:after {
  /*
  That strange stuff after the arrow forces iOS to render the arrow as the actual arrow glyph rather than an emoji version.
  Why anyone ever thought it was a good idea to render completely different glyphs when it's totally possible to just render the correct ones, we will never know.
  Luckily we can look this shit up on Stack Overflow nowadays.
  Fuck computers and/or the people in charge of them, honestly.
  See https://apple.stackexchange.com/questions/180400/how-to-turn-off-emojis-entirely-on-ios/240450
  */
  content: '↗\FE0E';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nimbus Sans', 'Helvetica Neue', Helvetica, Arial, serif;
}

h1 {
  text-align: center;
  font-size: 1em;
}

li {
  margin: 1em 0;
}

p {
  margin: 0 0 1em 0;
  /* text-indent: 3em; */
}

pre {
  font-size: 8px;
  line-height: 1;
  margin: 0;
}

.wave {
  text-align: center;
}

.logo {
  text-align: center;
  margin: 1em;
}

.nav {
  text-align: center;
  position: sticky;
  margin: 1em 0;
  padding: 0;
}

.nav li {
  display: inline;
  margin: 0.1em;
}

.content {
  overflow-wrap: break-word;
}
